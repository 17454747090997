<template>
  <v-data-table
        class="mt-5 table-vertical-line flex-grow-0"
        :headers="headers"
        :items="tools"
        :header-props="{ sortIcon: 'mdi-menu-down' }"
        :server-items-length="table.totalItems"
        hide-default-footer
        @click:row="rowClick"
        :options.sync="options"
      >
        <template v-slot:header.name="">
          <v-text-field
            @click.stop
            prepend-inner-icon="$search"
            placeholder="Tool name"
            v-model="queryOptions.searchName"
            outlined
            clearable
            hide-details
            class="table-search d-inline-block input-clear-small"
            dense
          ></v-text-field>
        </template>
        <template v-slot:header.login="">
          <v-text-field
            @click.stop
            prepend-inner-icon="$search"
            placeholder="Login"
            v-model="queryOptions.searchLogin"
            outlined
            clearable
            hide-details
            class="table-search d-inline-block input-clear-small"
            dense
          ></v-text-field>
        </template>
        <template v-slot:item.name="{ item }">
          <v-row class="ma-0 align-center">
            <v-avatar
              v-if="item.iconUrl"
              size="20"
              class="mr-2 rounded-0"
            >
              <img
                :src="item.iconUrl"
              >
              </v-avatar>
            <span class="fz-13 text-dgray">{{ item.name }}</span>
          </v-row>
        </template>
        <template v-slot:item.login="{ item }">
          <span class="fz-13 text-dgray">{{ item.login }}</span>
        </template>
        <template v-slot:item.password="{ item }">
          <span class="fz-13 text-dgray">{{ item.password }}</span>
        </template>
        <template v-slot:item.cost="{ item }">
          <span class="fz-13 text-dgray">{{ item.cost ? item.cost.amount : '' | numberFormat }}{{ item.cost ? item.cost.symbol : '' }}</span>
        </template>
        <template v-slot:item.expirationDate="{ item }">
          <span class="fz-13" :style="{'color': daysRemind(item.expirationDate)}">{{ item.expirationDate ? formatDate(item.expirationDate) : '' }}</span>
        </template>
        <template v-slot:item.apiKey="{ item }">
          <span class="fz-13 text-dgray">{{ item.apiKey }}</span>
        </template>
        <template v-slot:item.remove="{ item }">
          <v-dialog
              transition="dialog-bottom-transition"
              max-width="370"
              content-class="rounded-xl"
              overlay-color="rgba(9, 24, 73, 0.2)"
              overlay-opacity="1"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  elevation="0"
                  x-small
                  icon
                  fab
                >
                  <v-icon size="20"  class="stroke-gray">$delete</v-icon>
                </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card class="pa-7">
                <v-card-text class="py-0">
                  <div class="text-black font-weight-medium fz-18 text-center">Do you really want to delete {{ item.name }}?</div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-space-between pa-0">
                  <v-btn
                    class="rounded-lg text-none fz-16 fw-600"
                    outlined
                    width="150"
                    height="40"
                    @click="dialog.value = false"
                  >Return</v-btn>
                  <v-btn
                    elevation="0"
                    color="#FF0000"
                    width="150"
                    height="40"
                    class="rounded-lg white--text text-none fz-16 fw-600"
                    @click="deleteTool(item); dialog.value = false"
                  >
                    <v-icon left class="stroke-white">
                      $delete
                    </v-icon>
                    Delete</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </template>
      </v-data-table>
</template>

<script>
import numberFormat from '@/helpers/numberFormat'
import gql from 'graphql-tag'
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  name: 'ToolsTable',
  props: ['tools', 'queryOptions', 'table'],
  filters: {
    numberFormat
  },
  data() {
    return {
      options: {
        itemsPerPage: 999,
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        sortBy: [],
        sortDesc: []
      },
      headers: [
        {
          text: 'Tool name',
          align: 'start',
          value: 'name',
          class: 'table-icon-mb3',
          cellClass: 'pointer'
        },
        { text: 'Login', value: 'login', filterable: false, class: 'table-icon-mb3', cellClass: 'pointer' },
        { text: 'Password ', value: 'password', filterable: false, sortable: false, cellClass: 'pointer' },
        { text: 'Subscription cost', value: 'cost', filterable: false, cellClass: 'pointer' },
        { text: 'Expiration date', value: 'expirationDate', filterable: false, cellClass: 'pointer' },
        { text: 'API Key', value: 'apiKey', filterable: false, cellClass: 'pointer' },
        { text: '', value: 'remove', sortable: false, filterable: false, width: '50px', cellClass: 'pointer' }
      ]
    }
  },
  methods: {
    rowClick(item) {
      this.$emit('rowClick', item)
    },
    daysRemind(date) {
      const seconds = Math.floor(new Date(date) / 1000)
      const minutes = Math.floor(seconds / 60)
      const hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)
      const todaySeconds = Math.floor(new Date() / 1000)
      const todayMinutes = Math.floor(todaySeconds / 60)
      const todayHours = Math.floor(todayMinutes / 60)
      const todayDays = Math.floor(todayHours / 24)
      return (days - todayDays) < 1 ? '#FF0000' : (days - todayDays) < 14 ? '#F7931E' : '#5A6176'
    },
    sortTable () {
      let title = ''
      if (this.options.sortBy.length) {
        switch (this.options.sortBy[0]) {
          case 'name': title = 'NAME'
            break
          case 'login': title = 'LOGIN'
            break
          case 'cost': title = 'COST'
            break
          case 'expirationDate': title = 'EXPIRATION_DATE'
            break
        }
        if (this.options.sortDesc[0] === true) title += '_DESC'
        else title += '_ASC'
      }
      this.queryOptions.orderBy = title
    },
    formatDate (date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    async deleteTool(item) {
      await this.$apollo.mutate({
        mutation: gql`mutation deleteTool($id: ID! ) {
          tools {
            deleteTool(id: $id)
          }
        }`,
        // Parameters
        variables: {
          id: item.id
        }
      })
        .then(() => {
          this.$emit('addSnack', 'item.name', 'Success', item.name + ' was deleted with success')
          this.$emit('getAllTools')
        })
        .catch((err) => {
          this.$emit('addSnack', 'item.name', 'Error', err)
          console.log(err)
        })
    }
  },
  watch: {
    options: {
      handler() {
        this.sortTable()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
