<template>
  <v-col class="pa-5 height-100 d-flex flex-column">
    <resize-observer @notify="tableHeight"/>
<!--    <div class="height-100 d-flex flex-column scroll pa-5 pb-20">-->
      <tools-table
        :tools="tools"
        :queryOptions.sync="queryOptions"
        :table="table"
        @getAllTools="getAllTools"
        @rowClick="rowClick"
        @addSnack="addSnack"
        class="table-vertical-line"
        />
      <v-row class="ma-0 flex-grow-0 bb-lgray">
        <v-btn
          height="50"
          text
          tile
          color="#7552CC"
          @click="addTool"
          class="text-none px-2 fz-14">
          <b class="fz-16 fw-600 mr-1"> + </b>Add new tool
        </v-btn>
      </v-row>
      <v-row class="ma-0 flex-grow-1"></v-row>
      <v-row class="mx-0 flex-grow-0">
        <v-btn
        height="40"
        class="my-auto pl-2 mr-1 rounded-lg text-none fz-14 fw-600"
        outlined
        :disabled="queryOptions.page === 1"
        @click="queryOptions.page--"
        color="primary">
        <v-icon>mdi-chevron-left</v-icon>
        Previous
      </v-btn>
        <v-pagination
          v-model="queryOptions.page"
          class="my-4 pagination text-black fw-600"
          :length="table.totalPages"
          :total-visible="7"
          previous-aria-label="Previous"
        >
        </v-pagination>
        <v-btn
          height="40"
          class="my-auto ml-1 pr-2 rounded-lg text-none fz-14 fw-600"
          outlined
          :disabled="table.totalPages === queryOptions.page"
          @click="queryOptions.page++"
          color="primary">
          Next
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
<!--    </div>-->
    <v-navigation-drawer
      clipped
      fixed
      right
      width="380"
      class="overflow-unset pt-20"
      style="height: 100vh;"
      v-model="editToolShow"
    >
      <EditTool
        ref="editTool"
        :editTool="editTool"
        :editToolShow.sync="editToolShow"
        @addSnack="addSnack"
        @getAllTools="getAllTools" />
    </v-navigation-drawer>
    <v-overlay
      :z-index="0"
      :value="editToolShow"
      color="rgba(9, 24, 73, 0.2)"
      opacity="1"
      @click.native="editToolShow = false"
    />
    <v-snackbars
        :messages.sync="messages"
        color="red"
        timeout="2000"
        multi-line
        max-width="350"
        left
        rounded="lg"
        light
        elevation="4"
        content-class="snackbar"
      >
      <template v-slot:default="{ message }">
        <strong>{{ message.title }}</strong>
        <p>{{ message.text }}</p>
        <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
          <v-btn
            class="rounded-lg fz-12 fw-600 text-none border-none"
            outlined
            height="26"
            elevation="0"
            @click="doCopy('Error', message.text)"
          >Copy</v-btn>
        </v-row>
      </template>
    </v-snackbars>
  </v-col>
</template>

<script>
import gql from 'graphql-tag'
import ToolsTable from '@/components/App/tools/ToolsTable'
import { ResizeObserver } from 'vue-resize'
import 'vue-resize/dist/vue-resize.css'
import EditTool from '../../../components/App/tools/EditTool'
import VSnackbars from 'v-snackbars'
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  name: 'Tools',
  components: { EditTool, ToolsTable, ResizeObserver, 'v-snackbars': VSnackbars },
  data () {
    return {
      queryOptions: {
        orderBy: null,
        page: 1,
        pageSize: 13,
        searchLogin: null,
        searchName: null
      },
      table: {
        totalPages: null,
        totalItems: null
      },
      tools: [],
      editToolShow: false,
      editTool: null,
      messages: []
    }
  },
  methods: {
    formatDate (date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    rowClick (item) {
      this.editTool = JSON.parse(JSON.stringify(item))
      this.editTool.iconShow = this.editTool.iconUrl
      this.editTool.expirationDate = this.editTool.expirationDate ? this.formatDate(this.editTool.expirationDate) : ''
      this.editToolShow = true
    },
    addTool () {
      if (this.editTool === null) {
        this.editTool = {
          name: '',
          iconUrl: null,
          iconShow: null,
          login: '',
          password: '',
          cost: {
            amount: 0,
            currency: 'USD'
          },
          expirationDate: this.formatDate(new Date()),
          apiKey: ''
        }
      } else if (this.editTool.id) {
        this.editTool = {
          name: '',
          iconUrl: null,
          iconShow: null,
          login: '',
          password: '',
          cost: {
            amount: 0,
            currency: 'USD'
          },
          expirationDate: this.formatDate(new Date()),
          apiKey: ''
        }
        this.$refs.editTool.resetValidation()
      }
      this.editToolShow = true
    },
    tableHeight () {
      this.queryOptions.pageSize = Math.floor((window.innerHeight - 310) / 48) < 7 ? 7 : Math.floor((window.innerHeight - 310) / 48)
    },
    async getAllTools () {
      await this.$apollo.query({
        query: gql`query TeamMainTable($input: ToolsFilterInput! ) {
          tools {
            paginatedTools(input: $input) {
              data {
                id
                iconUrl
                iconUploadUrl
                expirationDate
                cost
                comment
                apiKey
                login
                name
                password
              }
              totalItems
              totalPages
            }
          }
        }`,
        // Parameters
        variables: {
          input: {
            orderBy: this.queryOptions.orderBy ? this.queryOptions.orderBy : null,
            page: this.queryOptions.page,
            pageSize: this.queryOptions.pageSize,
            searchLogin: this.queryOptions.searchLogin,
            searchName: this.queryOptions.searchName
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.tools = data.data.tools.paginatedTools.data
        this.table.totalItems = data.data.tools.paginatedTools.totalItems
        this.table.totalPages = data.data.tools.paginatedTools.totalPages
      }).catch((err) => {
        console.log(err)
      })
    },
    addSnack (item, result, text) {
      this.messages.push({ title: result, text: text })
    },
    doCopy (action, value) {
      const vm = this
      this.$copyText(value).then(function () {
        vm.addSnack(value, 'Success', action + ' text copied')
      }, function (e) {
        console.log(e)
      })
    }
  },
  watch: {
    queryOptions: {
      handler () {
        this.getAllTools()
      },
      deep: true
    }
  },
  created() {
    this.tableHeight()
  }
}
</script>

<style scoped>

</style>
